/* import lato font */
@import url('https://fonts.googleapis.com/css?family=Lato:300, 400,700&display=swap');

:root {
    --blue: #3E3B9E;
    --yellow: #FFC933;
    --yellow-dark: #FFD233; 
    --red-pale: #ff818d;
    --green-pastel: #77dd77;
    --black: #171C1E;
    --black-dry: #3f3f3f;
    --black-2: #171717;
    --black-3: #0A0914;
    --white: #FFFFFF;
    --gray: #666666;
    --gray-8: #888888;
    --gray-45: #454545;

    --green: #188038;
    --red: #d93025;
    --orange: #d99125;
    --darkGrey: #202124;
    --midGrey: #5a5c62;
    --lightGrey: #999ca3;
    --borderGrey: #e2e8f0;
}
.stripedTable thead th {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}
.stripedTable tbody tr:nth-child(odd) {
    background: #f8f9fa;
}

.stripedTable tr td {
    padding-top: 6px!important;
    padding-bottom: 6px!important;
}
.sidebar .navigation a {
    color: var(--black-dry);
    font-size: 15px;
}
.sidebar .navigation a:hover {
    color: var(--black-dry);
    font-weight: 600;;
}

.sidebar .navigation a.active {
    color: var(--black-dry)!important;
    font-weight: 700;
}


.pieChartLegend {
    font-size: 30px;
    vertical-align: bottom;
    width: 100%;
    height: 25px;
    
}

h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    color: var(--black-dry)
}
h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.2;
}
h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
}

p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--darkGrey);
}

p.branchTag {
    font-size: 12px;
}


